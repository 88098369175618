<template>
  <div class="bodys">
    <!--头部-->
    <header>
        <img
          src="https://fenglang-renji.oss-cn-hangzhou.aliyuncs.com/app/website/logo.png"
          alt=""
        />
      <div class="head-menu">
        <span @click="switchLanguage">中/EN</span>
        <van-icon  name="wap-nav" @click="openDrop"/>
      </div>
    </header>
    <div class="drop-menu-content" v-if="isDrop">
      <div class="drop-item" v-for="(item,index) in dropList" :key="item.value"  :class="[{'disabled': !item.click }, {'drop-active': dropIndex == index }]" @click="handlechange(item,index)">
          {{ $t(item.text) }}
      </div>
    </div>
    <!--内容部分-->
    <main>
      <!--背景图-->
      <div class="main_image">
        <div class="main_text">
          <!-- <span
            >Connecting&nbsp; Anything&nbsp; AnyWhere&nbsp; All&nbsp; at
            once</span
          > -->
          <span>{{ $t("lang.advertisement.reach") }}, {{ $t("lang.advertisement.nee") }}</span>
        </div>
      </div>
      <!--技术-->
      <div class="main_jishu" id="technology">
        <!--技术1-->
        <div class="jishu_one">
          <div class="jishu_img">
            <div class="jishu_texts">
              <span>{{ $t("lang.content.omnipresent") }}
                <p style="color: #00e4ff">{{$t("lang.content.things")}}</p></span>
              <span>{{ $t("lang.content.coverage") }}</span>
            </div>
          </div>
          <div class="jishu_images"></div>
        </div>
        <!--技术2-->
        <div class="jishu_two">
          <div class="jishu_imgs">
            <div class="jishu_tex">
              <span
                >{{ $t("lang.content.lose") }}
                <p style="color: #00e4ff">{{ $t("lang.content.net") }}</p></span
              >
              <span
                > {{ $t("lang.content.cost") }}</span
              >
            </div>
          </div>
          <img
            src="https://fenglang-renji.oss-cn-hangzhou.aliyuncs.com/app/website/jishu2-2.png"
            alt=""
          />
        </div>
        <!--技术3-->
        <div class="jishu_three">
          <img src="../assets/moblite/WUYU Protocol      .png" alt="" />
          <div class="jishu_bottom">
            <div class="text" style="color: white">{{ $t("lang.content.network") }}</div>
            <div class="jianbian">
              <ul>
                <li>{{ $t("lang.content.mass") }}</li>
                <li>{{ $t("lang.content.low") }}</li>
                <li>{{ $t("lang.content.design") }}</li>
              </ul>
              <ul>
                {{ $t("lang.content.tiandi") }}
              </ul>
              <ul>
                <li>{{ $t("lang.content.comon") }}</li>
                <li>{{ $t("lang.content.guangbo") }}</li>
                <li>{{ $t("lang.content.peizhi") }}</li>
              </ul>
            </div>
          </div>
          <div class="jishu_mozu">
            <h1>{{ $t("lang.content.mozu") }}</h1>
            <span>{{ $t("lang.content.shuzi") }}</span>
          </div>
        </div>
        <!--技术4-->
        <div class="jishu_for">
          <div class="chanpin_img">
            <div class="jishu_text">
              <span style="display: flex">{{ $t("lang.content.hangye")}}
                <p style="color: #00e4ff">{{$t("lang.content.gaodu")}}</p></span
              >
              <span class="texts"
                >{{$t("lang.content.fazhan")}}</span
              >
            </div>
          </div>
          <div class="contina_image">
            <ul>
              <li>
                <img
                  src="https://fenglang-renji.oss-cn-hangzhou.aliyuncs.com/app/website/jishu1.png"
                  alt=""
                />
                <span>{{ $t("lang.content.renyuan") }}</span>
              </li>
              <li>
                <img
                  src="https://fenglang-renji.oss-cn-hangzhou.aliyuncs.com/app/website/jishu2.png"
                  alt=""
                />
                <span>{{ $t("lang.content.yunshu") }}</span>
              </li>
              <li>
                <img
                  src="https://fenglang-renji.oss-cn-hangzhou.aliyuncs.com/app/website/jishu3.jpg"
                  alt=""
                />
                <span>{{ $t("lang.content.kaicai") }}</span>
              </li>
            </ul>
            <ul>
              <li>
                <img
                  src="https://fenglang-renji.oss-cn-hangzhou.aliyuncs.com/app/website/jishu6.png"
                  alt=""
                />
                <span>{{ $t("lang.content.shiyou") }}</span>
              </li>
              <li>
                <img src="../assets/moblite/hulin.png" alt="" />
                <span>{{ $t("lang.content.kangzai") }}</span>
              </li>
              <li>
                <img
                  src="https://fenglang-renji.oss-cn-hangzhou.aliyuncs.com/app/website/jishu4.png"
                  alt=""
                />
                <span>{{ $t("lang.content.haishi") }}</span>
              </li>
            </ul>
            <ul>
              <li>
                <img
                  src="https://fenglang-renji.oss-cn-hangzhou.aliyuncs.com/app/website/jishu9.png"
                  alt=""
                />
                <span>{{ $t("lang.content.nengyuan") }}</span>
              </li>
              <li>
                <img
                  src="https://fenglang-renji.oss-cn-hangzhou.aliyuncs.com/app/website/jishu5.jpg"
                  alt=""
                />
                <span>{{ $t("lang.content.nongye") }}</span>
              </li>
              <li>
                <img
                  src="https://fenglang-renji.oss-cn-hangzhou.aliyuncs.com/app/website/jishu7.png"
                  alt=""
                />
                <span>{{ $t("lang.content.jiuyuan") }}</span>
              </li>
            </ul>
          </div>
          <div class="jishu_xiangqing">
            <span>{{ $t("lang.content.details") }}</span>
            <span><van-icon name="arrow" /></span>
          </div>
        </div>
      </div>
      <!--产品-->
      <div class="chanpin" id="product">
        <!--产品1-->
        <div class="chanpin_on">
          <div class="chanpin_top">
            <div class="wuyu_left" style="color: white">
              <span style="color: #ffea00">{{ $t("lang.prodect.wuyu") }} </span>{{ $t("lang.prodect.weixing") }}
            </div>
            <div class="wuyu_right">
              <img src="../assets/moblite/x.png" alt="" />
            </div>
          </div>
          <div class="centers"></div>
          <div class="wuyu_bottom">
            <div class="chanpin_liaojie">
              <div class="text">
                {{ $t("lang.prodect.wulian") }}
              </div>
              <div class="xiangqing_box">
                <span>{{ $t("lang.prodect.liaojie") }}</span> <van-icon name="arrow" />
              </div>
            </div>
            <img
              class="chanpin_one"
              src="../assets/images/chanpin1-1.png"
              alt=""
            />
          </div>
        </div>
        <!--产品2-->
        <div class="chanpin_two">
          <div class="top">
            <img src="../assets/moblite/l.png" alt="" />
            <span><p style="color: #78ff00">{{ $t("lang.prodect.shebeiapp") }}</p>&nbsp;{{ $t("lang.prodect.xingshi") }}</span>
          </div>
          <div class="background_image"></div>
          <div class="bottom">
            <div class="left">
              <img src="../assets/moblite/shubiao.png" alt="" />
            </div>
            <div class="right">
              <div class="text">
                {{ $t("lang.prodect.fengwoapp") }}
              </div>
              <div class="xiangqing_box">
                <span>{{ $t("lang.prodect.liaojie") }}</span> <van-icon name="arrow" />
              </div>
            </div>
          </div>
        </div>
        <!--产品3-->
        <div class="chanpin_three">
          <div class="top">
            <span
              ><p style="color: #ff7800">{{ $t("lang.prodect.gongyeapp") }}</p>&nbsp;{{ $t("lang.prodect.wxdtu") }}</span
            >
            <img src="../assets/moblite/D.png" alt="" />
          </div>
          <div class="background_image"></div>
          <div class="bottom">
            <div class="left">
              <div class="text">
                {{ $t("lang.prodect.wyapp") }}
              </div>
              <div class="xiangqing_box">
                <span>{{ $t("lang.prodect.liaojie") }}</span> <van-icon name="arrow" />
              </div>
            </div>
            <div class="right">
              <img src="../assets/moblite/dao.png" alt="" />
            </div>
          </div>
        </div>
        <!--产品4-->
        <div class="chanpin_for">
          <div class="top">
            <img src="../assets/moblite/m.png" alt="" />
            <span><p style="color: blue">{{ $t("lang.prodect.haiyangapp") }}</p>&nbsp;{{ $t("lang.prodect.fubiao") }}
            </span>
          </div>
          <div class="background_image"></div>
          <div class="bottom">
            <div class="left">
              <img src="../assets/moblite/yx.png" alt="" />
            </div>
            <div class="right">
              <div class="text">
                {{ $t("lang.prodect.jiankong") }}
              </div>
              <div class="xiangqing_box">
                <span>{{ $t("lang.prodect.liaojie") }}</span> <van-icon name="arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--市场-->
      <div class="shichang" id="market">
        <h1>
          {{ $t("lang.marketplace.wangluo")}}
          {{ $t("lang.marketplace.quanqiu") }}
          <span style="color: #00e4ff">{{ $t("lang.marketplace.changjing") }}</span>
        </h1>
        <van-swipe class="my-swipe" indicator-color="white" :autoplay="3000">
          <van-swipe-item v-for="(item, index) in imgList" :key="item.id">
            <div class="app_body" v-if="index == 0"></div>
            <!--框1-->
            <div class="app_content" v-if="index == 0">
              <span>{{ $t("lang.marketplace.yingji") }}</span>
              <span>{{ $t("lang.marketplace.jy") }}</span>
            </div>
            <div class="app_body" v-if="index == 1"></div>
            <!--框2-->
            <div class="app_content" v-if="index == 1">
              <span>{{ $t("lang.marketplace.huanjing") }}</span>
              <span>{{ $t("lang.marketplace.hj") }}</span>
            </div>
            <div class="app_body" v-if="index == 2"></div>
            <!--框3-->
            <div class="app_content" v-if="index == 2">
              <span>{{ $t("lang.marketplace.hai") }}</span>
              <span>{{ $t("lang.marketplace.bj") }}</span>
            </div>
            <div class="app_body" v-if="index == 3"></div>
            <!--框4-->
            <div class="app_content" v-if="index == 3">
              <span>{{ $t("lang.marketplace.zichan") }}</span>
              <span>{{ $t("lang.marketplace.dianwang") }}</span>
            </div>
            <img :src="`${imgUrl}` + item.img" alt="" />
          </van-swipe-item>
        </van-swipe>
        <div class="xiangqing_box">
          <span>{{ $t("lang.prodect.liaojie") }}</span> <van-icon name="arrow" />
        </div>
      </div>
      <!--我们-->
      <div class="we-content-wraper" id="we">
        <div class="we_text" >
          <h1 style="color: white">
            {{ $t("lang.we.yiqi")}}<span style="color: #ff00cc">{{ $t("lang.we.shijie") }}</span>
          </h1>
          <div class="text">
            <ul>
              {{ $t("lang.we.beijing") }}
            </ul>
            <ul>
              {{ $t("lang.we.chengwei") }}
            </ul>
            <ul>
              {{ $t("lang.we.jianchi") }}
            </ul>
          </div>
        </div>
      </div>
      <footer>
        <div class="footer-nav">
            <span>{{ $t("lang.we.chanpin") }}</span>
            <span>|</span>
            <span>{{ $t("lang.we.jr") }}</span>
            <span>|</span>
            <span>{{ $t("lang.we.fw") }}</span>
            <span>|</span>
            <span>{{ $t("lang.we.ys") }}</span>
        </div>
        <div class="footer-message">
          <li>{{ $t("lang.we.hz") }}：sale@spaceiot.com.cn</li>
          <li>{{ $t("lang.we.mt") }}：pr@spaceiot.com.cn</li>
          <li>{{ $t("lang.we.rc") }}：hr@spaceiot.com.cn</li>
          <li>{{ $t("lang.we.dizhi") }}</li>
        </div>
        <a href="http://www.beian.gov.cn/">
          <div class="footer-beian">
            <img
                  :src="`${imgUrl}gongan.png`"
                  alt=""
            />{{ $t("lang.we.beian") }}
          </div>
        </a>
      </footer>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl:
        "https://oss.xinyuekongjian.com/static/website/mobile/",
      imgList: [
        {
          id: 1,
          img: "shichang2.png",
        },
        {
          id: 2,
          img: "shichang3.png",
        },
        {
          id: 3,
          img: "shichang4.png",
        },
        {
          id: 4,
          img: "shichang1.png",
        },
      ],
      dialogVisible: false,
      boolleans:false,
      dropList: [
        { text: 'lang.header.technology', value: 'technology',click:true },
        { text: 'lang.header.product', value: 'product' ,click:true },
        { text: 'lang.header.market', value: 'market' ,click:true },
        { text: 'lang.header.news', value: 'news',click:false},
        { text: 'lang.header.mall', value: 'mall' ,click:false},
        { text: 'lang.header.we', value: 'we',click:true},
        { text: 'lang.header.join', value: 'join' ,click:false}
      ],
      dropIndex:0,
      isDrop:false
    };
  },
  created() {},
  mounted() {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      this.$i18n.locale = storedLanguage;
    }
  },
  methods: {
    switchLanguage() {
      this.$i18n.locale = this.$i18n.locale === "zh" ? "en" : "zh";
      localStorage.setItem("language", this.$i18n.locale);
    },
    handlechange(item,index) {
      if(!item.click) return
      this.dropIndex = index
      this.isDrop = false
      let targetbox = document.getElementById(`${item.value}`);
      window.scrollTo({
          top:targetbox.offsetTop - 100
      })
    },
    openDrop() {
      this.isDrop =!this.isDrop
    }
  },
};
</script>
<style scoped lang="scss">
/deep/::v-deep.el-icon-close:before {
    content: "";
}

/deep/.el-dialog__headerbtn .el-dialog__close {
     color: white;
}
.bodys {
  width: 100%;
  background: #282828;
}
header {
  width: 100%;
  height: 99px;
  background: white;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 10000;
  justify-content: center;
  border-bottom: 1px solid #b2b2b2;
  img {
    width: 98px;
    height: 98px;
  }
  .van-icon {
    font-size: 45px;
  }
  .head-menu {
    position: absolute;
    top: 50%;
    right: 50px;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
    span {
      display: inline-block;
      font-size: 20px;
      color: #b2b2b2;
      margin-right: 20px;
    }
  }
}
footer {
  background: rgb(40, 40, 40);
  padding-bottom: 20px;
  .footer-nav {
    width: 90%;
    margin: 20px auto 0;
    font-size: 12px;
    display: flex;
    color: #fff;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .footer-message {
    width: 90%;
    margin: 0 auto;
    li {
      margin-top: 10px;
      font-size: 12px;
      color: #fff;
    }
  }
  .footer-beian {
    width: 90%;
    margin: 0 auto;
    font-size: 12px;
    color: #fff;
    margin-top: 10px;
    img {
      margin-right: 5px;
    }
  }
}
.drop-menu-content {
  background: #fff;
  width: 100%;
  position: fixed;
  z-index: 10000;
  top: 99px;
  .drop-item {
    display: flex;
    padding: 6px;
    justify-content: center;
  }
  .drop-active {
    background: #000;
    color: #fff;
  }
  .disabled {
    color: #9fa0a0;
  }
}
main {
  width: 100%;
  height: 100%;
}

.main_image {
  width: 100%;
  margin-top: 99px;
  height: 360px;
  background: url("../assets/moblite/tb.png");
  overflow: hidden;
  background-size: 100% 100%;
}

.main_text {
  width: 90%;
  height: 67px;
  margin: auto;
  margin-top: 48px;
  // background: red;
  font-size: 23px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.jishu_img {
  width: 100%;
  margin-top: 100px;
  background: url("../assets/moblite/SPACE IoT.png");
  background-size: 100% 100%;
  height: 125px;
  position: relative;
}

.jishu_imgs {
  width: 100%;
  margin-top: 100px;
  background: url("../assets/moblite/WUYUNET.png");
  background-size: 100% 100%;
  height: 125px;
  position: relative;
}

.jishu_texts {
  width: 90%;
  margin: auto;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 44px;
  left: 25px;

  span:nth-child(1) {
    width: 100%;
    // height: 21px;
    font-size: 24px;
    font-weight: bolder;
    display: flex;
  }

  span:nth-child(2) {
    font-size: 12px;
  }
}

.jishu_tex {
  width: 90%;
  margin: auto;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 44px;
  left: 25px;

  span:nth-child(1) {
    width: 100%;
    // height: 21px;
    font-size: 24px;
    font-weight: bold;
    display: flex;
  }

  span:nth-child(2) {
    font-size: 13px;
  }
}
.jishu_images {
  width: 85%;
  height: 369px;
  margin: auto;
  background: url("../assets/moblite/jishu2-1.png");
  background-size: 100% 100%;
}

.jishu_one {
  width: 100%;
  height: 700px;
  // background: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.jishu_two {
  width: 100%;
  height: 650px;
  // background: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;
}

.jishu_two img {
  width: 85%;
  height: 336px;
}

.jushu_three {
  width: 100%;
  height: 750px;
  margin-top: 80px;
}

.jishu_three img {
  width: 100%;
  height: 180px;
}

.jishu_bottom {
  width: 100%;
  height: 450px;
  margin: auto;
  // background: red;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  .text {
    font-size: 20px;
  }
}
.jianbian {
  width: 90%;
  height: 380px;
  background: url("../assets/moblite/jishu3-3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.jianbian ul {
  width: 80%;
  height: 10px;
  display: flex;
  align-items: center;
  li {
    flex: 1;
    text-align: center;
  }
}

.jianbian ul:nth-child(1) {
  color: #9fa0a0;
  font-size: 16px;
}

.jianbian ul:nth-child(3) {
  color: #9fa0a0;
  font-size: 18px;
}
.jianbian ul:nth-child(2) {
  width: 100%;
  height: 40px;
  // background: red;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
}

.jishu_mozu {
  width: 90%;
  height: 100px;
  margin: auto;
  // background: pink;
  font-weight: 400;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;

  h1 {
    color: white;
    font-size: 19px;
    margin-bottom: 10px;
  }
  span {
    font-size: 13px;
    color: #9fa0a0;
  }
}

.jishu_mozu h1 {
  color: white;
}

.jishu_mozu span {
  color: #9fa0a0;
}

.jishu_for {
  width: 100%;
  margin: auto;
  height: 1340px;
}

.chanpin_img {
  width: 100%;
  margin-top: 100px;
  background: url("../assets/moblite/new.png");
  background-size: 100% 100%;
  height: 125px;
  position: relative;
  margin-bottom: 60px;
}

.chanpin_img .jishu_text {
  width: 90%;
  // background: pink;
  margin: auto;
  position: absolute;
  top: 44px;
  left: 25px;
  // background: red;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  span:nth-child(1) {
    width: 100%;
    // height: 21px;
    font-size: 24px;
    display: flex;
    font-weight: bold;
  }

  span:nth-child(2) {
    font-size: 13px;
  }
  .texts {
    font-size: 12px;
  }
}

.contina_image {
  width: 90%;
  height: 580px;
  margin-top: 50px;
  margin: auto;
  // background: red;

  ul {
    width: 100%;
    height: 310px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    li {
      width: 33.3%;
      height: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }
      span {
        position: absolute;
        margin-top: 280px;
        font-size: 15px;
        left: 10px;
        color: white;
      }
    }
  }
}

.jishu_xiangqing {
  width: 300px;
  padding: 4px 0;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin-top: 415px;
  float: right;
  margin-right: 20px;

  span:nth-child(1) {
    margin-left: 5px;
  }

  span:nth-child(2) {
    margin-right: 25px;
  }
}

.chanpin {
  width: 100%;
  height: 100%;
  margin: auto;
}
.chanpin_on {
  width: 95%;
  position: relative;
}
.chanpin_top {
  width: 90%;
  height: 200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wuyu_left {
  font-size: 21px;
  font-weight: 700;
  margin-top: 10px;
}
.wuyu_right img {
  width: 168px;
  height: 174px;
}
// .center_img{
//   width: 100%;
//   height: 225px;
//   // position: relative;
// }

.wuyu_bottom {
  width: 90%;
  margin: auto;
  // background: orange;
  display: flex;
  justify-content: space-between;

  .chanpin_one {
    width: 227px;
    position: absolute;
    right: 15px;
    top: 360px;
  }
}

.chanpin_liaojie {
  width: 280px;
  // background: rgb(255, 0, 157);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .text {
    width: 100%;
    font-size: 12px;
    color: white;
    margin-top: 30px;
    // background: brown;
  }

  .xiangqing_box {
    width: 100%;
    padding: 4px 0;
    font-size: 18px;
    margin-top: 20px;
    background: yellow;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin-left: 5px;
    }
    .van-icon {
      margin-right: 25px;
    }
  }
}

.centers {
  width: 562px;
  height: 225px;
  background: url("../assets/images/chanpin1-5.jpg");
  background-size: 100% 100%;
}

.chanpin_two {
  width: 100%;
  height: 660px;
  // background: orange;
  margin-top: 80px;
  position: relative;

  .top {
    width: 90%;
    height: 180px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 47px;
      height: 180px;
    }
    span {
      display: flex;
      font-size: 24px;
      color: white;
      margin-top: 40px;
    }
  }
  .background_image {
    width: 100%;
    height: 225px;
    background: url("../assets/moblite/cd.png");
    background-size: 100% 100%;
  }
  .bottom {
    width: 90%;
    height: 270px;
    margin: auto;
    // background: brown;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      width: 204px;
      height: 287px;

      img {
        width: 160px;
        height: 247px;
        position: absolute;
        top: 360px;
        left: 60px;
      }
    }
    .right {
      width: 280px;
      height: 240px;
      font-size: 12px;
      color: white;
      margin-top: 30px;
      // background: rgb(54, 42, 165);

      .text {
        width: 100%;
        // background: pink;
        font-size: 12px;
      }
      .xiangqing_box {
        width: 280px;
        height: 30px;
        font-size: 18px;
        background: rgb(19, 206, 44);
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          margin-left: 5px;
        }
        .van-icon {
          margin-right: 25px;
        }
      }
    }
  }
}

.chanpin_three {
  width: 100%;
  // background: orange;
  margin-top: 80px;
  position: relative;

  .top {
    width: 90%;
    height: 180px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 165px;
      height: 180px;
    }
    span {
      display: flex;
      font-size: 24px;
      color: white;
      margin-bottom: -10px;
    }
  }
  .background_image {
    width: 100%;
    height: 225px;
    background: url("../assets/moblite/fs.png");
    background-size: 100% 100%;
    position: absolute;
    top: 145px;
  }
  .bottom {
    width: 90%;
    margin: auto;
    margin-top: 190px;
    // background: brown;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      width: 300px;
      font-size: 12px;
      color: white;
      margin-top: 30px;
      // background: rgb(54, 42, 165);

      .text {
        width: 100%;
        // background: pink;
        font-size: 12px;
      }
      .xiangqing_box {
        width: 280px;
        height: 30px;
        font-size: 18px;
        background: orange;
        margin-top: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          margin-left: 5px;
        }
        .van-icon {
          margin-right: 25px;
        }
      }
    }
    .right img {
      width: 165px;
      height: 368px;
      position: absolute;
      top: 280px;
      right: 50px;
    }
  }
}

.chanpin_for {
  width: 100%;
  // background: orange;
  margin-top: 80px;
  position: relative;

  .top {
    width: 90%;
    height: 180px;
    // background: pink;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 175px;
      height: 180px;
    }
    span {
      display: flex;
      font-size: 24px;
      color: white;
      margin-bottom: -10px;
    }
  }
  .background_image {
    width: 100%;
    height: 225px;
    margin-top: -30px;
    z-index: 100;
    position: relative;
    background: url("../assets/moblite/dh.png");
    background-size: 100% 100%;
  }
  .bottom {
    width: 90%;
    margin: auto;
    // background: brown;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      width: 204px;
      height: 287px;

      img {
        width: 60px;
        height: 340px;
        position: absolute;
        top: 280px;
        left: 60px;
        z-index: 100;
      }
    }
    .right {
      width: 500px;
      font-size: 12px;
      color: white;
      // background: rgb(54, 42, 165);

      .text {
        width: 100%;
        // background: pink;
        font-size: 12px;
        margin: 20px 0;
      }
      .xiangqing_box {
        width: 280px;
        height: 30px;
        font-size: 18px;
        background: blue;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        span {
          margin-left: 5px;
        }
        .van-icon {
          margin-right: 25px;
        }
      }
    }
  }
}

.shichang {
  width: 100%;
  height: 700px;
  background: #9999;

  h1 {
    font-size: 24px;
    margin-left: 60px;
    color: white;
    padding-top: 30px;
    margin-bottom: 30px;
  }
  .xiangqing_box {
    width: 280px;
    height: 30px;
    font-size: 18px;
    float: right;
    background: rgb(0, 204, 255);
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;

    span {
      margin-left: 5px;
    }
    .van-icon {
      margin-right: 25px;
    }
  }
}

.my-swipe {
  width: 90%;
  height: 500px;
  margin: auto;
  overflow: hidden;
  z-index: 9999;
  // background: red;
  position: relative;
}

.my-swipe .van-swipe-item {
  width: 400px;
  height: 450px;
  color: #fff;
  background-color: #39a9ed;

  img {
    width: 100%;
    height: inherit;
    overflow: hidden;
  }
}

.app_body {
  width: 100%;
  height: 160px;
  background: black;
  opacity: 0.2;
  position: absolute;
  top: 290px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.app_content {
  width: 92%;
  height: 100px;
  margin: auto;
  // background-color: #b11919;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 20px;
  top: 310px;
  // margin-left:10px;

  span:nth-child(1) {
    font-size: 22px;
  }
  span:nth-child(2) {
    font-size: 12px;
    color: #ffffff !important;
  }
}

::v-deep .van-swipe__indicator--active {
  background-color: #39a9ed !important;
}

.we-content-wraper{
  width: 100%;
  height: 100%;
  background: rgb(58, 58, 58);
  padding-top: 40px;
  padding-bottom: 20px;
  // background: pink;
  .we_text {
    width: 90%;
    height: 100%;
    // background: green;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;

    h1 {
      font-size: 24px;
    }
    .text {
      width: 100%;
      // background: brown;
      font-size: 12px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #ffffff;
      flex-direction: column;
      margin-top: 10px;
      ul {
        margin-bottom: 20px;
      }
      ul:nth-child(1) {
        width: 100%;
        // height: 75px;
        // background: red;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
      }
      ul:nth-child(2) {
        width: 100%;
        // height: 145px;
        // background: yellow;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
      }
      ul:nth-child(3) {
        width: 100%;
        // height: 145px;
        // background: blue;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }
}
</style>
