// import Vue from 'vue'
// import VueRouter from 'vue-router'
// // import Home from '../views/homeView.vue'
// import MoblieTeminal from '../views/mobileTerminal.vue'

// Vue.use(VueRouter)

// const routes = [
//   // {
//   //   path:'*',
//   //   redirect:'/howeView'
//   // },
//   {
//     path: '/',
//     name: 'MoblieTeminal',
//     component: MoblieTeminal
//   }]



// // const routesM=[
// //   {
// //     path:'*',
// //     redirect:'/moblieTeminal'

// //   },
// //   {
// //   path:'/moblieTeminal',
// //   name:'MoblieTeminal',
// //   component:MoblieTeminal
// // }]

// // var routes = []

// // if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)){
// //   routes = routesM
// // }else{
// //   routes = routesPC
// // }

// const router = new VueRouter({
//  // mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

// export default router

import VueRouter from "vue-router";
import Vue from "vue";
Vue.use(VueRouter);
import Home from "../views/homeView.vue";
import MoblieTeminal from "../views/mobileTerminal.vue";

const routes = [
  {
    path: "/app",
    name: "MoblieTeminal",
    component: MoblieTeminal,
  },
  {
    path: "/pc",
    name: "Home",
    component: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 在router.js文件中定义导航守卫
router.beforeEach((to, from, next) => {
 
  const isPC =isPCNot()
  const isOnPCRoute = to.path === '/pc'
  const isOnAppRoute = to.path === '/app'

  if (isPC && !isOnPCRoute) {
    next({ path: '/pc' })
  } else if (!isPC && !isOnAppRoute) {
    next({ path: '/app' })
  } else {
    next()
  }
});
function  isPCNot() {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
      }
  }
  return flag;
}


export default router;
