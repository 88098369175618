export default {
  lang: {
    header: {
      //公司名称
      SpaceIoT:'鹏鹄物宇',
      // 头部导航栏
      technology: '技术',
      product: '产品',
      market: '市场',
      news: '新闻',
      consult: '收费资讯',
      we: '我们',
      join: '加入',
      serch: '搜索'
    },
    advertisement: {
      // 广告
      about: '关于卫星互联网',
      more: '了解详情信息',
      // 框1
      reach: '物之所及',
      nee: '一网无际',
      introduce: '遍布全球的每个角落实现网络链接，为个人、企业、设备提供连接世界的数据服务',
      product: '详细了解我们的技术',
      // 框2
      technology: '详细了解我们的产品',
      // 框3
      market: '详细了解我们的市场'
    },
    content: {
      // 技术1 框1
      omnipresent: '无处不在的',
      things: '卫星物联网 ',
      coverage: '地面通信网络仅覆盖全球10%的地表面积,而卫星通信网络则可以做到100%覆盖',
      details: '了解技术详情',
      // 框3
      lose: '永不失联的',
      net: '物宇网络系统',
      cost: '我们致力于为全球任何区域提供低成本的网络解决方案，确保您永远不会超出网络覆盖范围',
      us: '联系我们',
      // 技术2
      network: '网络',
      any: '适配任何弯管网络，通过卫星合作，覆盖全球。',
      mass: '大容量接入',
      low: '低时延传输',
      design: '低功耗设计',
      tiandi: '“天地物语”通信协议(WUYU Protocol)',
      comon: '双向通信',
      guangbo: '广播业务',
      peizhi: '灵活配置',
      mozu: '模组&基站',
      shuzi: '全域全时全程百分之百数据链条，数字化，信息化，智能化，安全、降本、增效。',
      // 技术3
      hangye: '赋能行业发展',
      gaodu: '新高度',
      fazhan: '鹏鹄物宇通过配合用户进行定制化终端研制、搭建云平台，为用户提供定制化的一站式行业解决方案，以卫星物联网赋能行业发展',
      renyuan: '人员',
      yunshu: '运输',
      kaicai: '开采',
      haishi: '海事',
      nongye: '农业',
      nengyuan: '能源',
      kangzai: '抗灾',
      jiuyuan: '救援',
      shiyou: '石油&天然气',
      weilai: '未来'

    },
    prodect: {
      // 产品1
      wuyu: '物宇 THE X',
      weixing: '卫星物联网通信模组',
      wulian: '物宇X卫星物联网通信模组基于地面窄带系统通信芯片重构，通过自有通信协议，直链卫星。产品成熟稳定，功耗极低，便于集成，可实现全球窄带双向通信',
      liaojie: '了解产品详情',
      tianxian: '天线',
      tongxin: '通信模组',
      chuanganqi: '传感器',
      // 产品2
      shebei: '卫星个人通信设备',
      fengwo: '当您处于没有蜂窝网络的地方，拥有可靠的通信设备非常关键。物宇I应急通信设备为您提供便捷专业的辅助功能和值得信赖的保护。在发生危险的时候通过强大的卫星网络提供24/7/365的救援服务',
      xiang: '了解产品详情',
      shebeiapp: '物宇 THE I',
      xingshi: '卫星信使',
      fengwoapp: '物宇 THE I 卫星信使,可在没有地面的区域为客户提供可靠实时的卫星双向文字通信,进行位置追踪，实现高效应急救援和实时数据回传等功能。是一款“小体积、低成本、长待机”的个人卫星短码通信新型设备。',
      // 产品3
      haiyang: '海洋浮标监控设备',
      jiankong: '物宇M海洋浮标监控设备，利用现有的商业化传感器器件，进行简约设计，配合低功耗通信模组，实现“低成本、低功耗、长待机、可环保”的海洋浮标产品，配置温度传感、盐度传感、惯性测量单元/加速度计、位置传感北斗+GPS导航模块四类通用传感器。另外，海洋浮标将预留拓展端口，方便接入其他定制化传感器如声纳、云层相机、边缘计算模块、终端安全模块等从而实现对海洋信息的全面采集和监控。',
      peihe: '物宇 THE M配合相应AI算法，研制出了洋流预测、旋涡预警、敌情通报、生物体征监测等功能。为客户提供海洋卫星物联网数据平台一站式解决方案',
      haiyangapp: '物宇 THE M',
      fubiao: '海洋浮标',
      // 产品4
      gongye: '工业物联网监控设备',
      wy: '物宇D工业物联网监控设备，配置五类工业化标准接口，无需对现有工业设备进行大量调整，通过简单联调即可实现现有设备上星，实现全球实时监控。通过低功耗卫星通信模组，实现“低成本、低功耗、长待机”的工业卫星物联网新型终端。物宇 THE D配合相应平台和算法，提供一站式解决方案，可以对工业设备运行情况，设备报警，电子围栏等关键数据进行全程采集，满足工业客户降本增效的核心需求',
      gongyeapp: '物宇 THE D',
      wxdtu: '卫星DTU',
      wyapp: '物宇THE D卫星物联网工业终端,配置三类通讯接口(RS485、RS232、TTL),无需对现有传感设备进行大量调整,通过简单联调即可实现现有设备上星,实现全球实时设备监控和数据回传。内置自研5GNTN低功耗卫星通信模组,是一款“低成本、低功耗、长待机”的工业卫星物联网新型设备。'
    },
    marketplace: {
      // 市场 左侧 框1
      wangluo: '物宇网络在',
      quanqiu: '全球的',
      changjing: '应用场景',
      zichan: '资产监控',
      yingji: '应急救援',
      huanjing: '环境监测',
      hai: '海洋物流',
      lianxi: '联系我们',
      // 右侧
      dianwang: '电网杆塔及石油管道遍布全球各地，绝大部分没有地面网覆盖，卫星物联网可以有效监测基础建设状态，实时报警，减少损失',
      jy: '应急救援市场主要服务在没有地面网络覆盖区域的企业巡检人员，船员，专业救险人员，以及登山，野营，滑雪，徒步等爱好者',
      hj: '环境监测市场主要聚焦于全国范围内的“水、气、土”质量监测，监测传感设备一般部署在人口稀少地区，没有地面网覆盖',
      bj: '海上存在着大量的运输船只和渔船，以及海量的集装箱，海上设备无法建设地面网，仅能使用卫星进行通信，用以设备监控和报警'

    },
    we: {
      // 我们
      yiqi: '与物宇一起',
      shijie: '链接世界',
      beijing: '北京鹏鹄物宇科技发展有限公司，是全球首家完成“天地一体”通信协议星地联调，实现低成本卫星物联网的商业航天领军企业；是全球低成本卫星物联网运营商及解决方案商，为全球客户提供全时、全程、全域的物联网设备监控和数据采集服务',
      jianchi: '鹏鹄物宇坚持“国内打样、全球布局”的全球化运营模式。国内，基于工信部已明确将天地一体纳入到十四五规划重点的政策优势，卫星物联网“顺势而为”，未来可期。全球，鹏鹄物宇已与拉美、欧洲、中东等多家海外企业及走出去的国企对接，开展合作，计划尽快的将中国模式复制至海外，形成全球运营布局，构建全球空天大数据体系',
      chengwei: '目前，鹏鹄物宇在天地一体通信协议成功验证的基础上，同步完成了卫星物联网核心产品样机研制，包括卫星物联网芯片及模组，工业终端和便携终端，数据平台等，已具备试商业运营条件。2022年6月18日，鹏鹄物宇联合国际领先的芯片厂商和卫星厂商，完成了地面通信协议、地面芯片及现有卫星的在轨联调，成为',
      shixian: '全球首家实现“天地一体”技术闭环并可提供商业化运营的低成本卫星物联网服务商',
      shiming: '使命',
      tiandi: '天地一体,万物互联',
      yuanjing: '愿景',
      quanqiu: '成为全球最大的天地一体通信技术和信息服务提供商',
      jiazhi: '价值观',
      chengshi: '仰望星空，脚踏实地；臻于至善，厚德载物',
      // 关于我们
      chanpin: '产品网站',
      jr: '加入我们',
      fw: '服务协议',
      ys: '隐私协议',
      hz: '合作垂询',
      mt: '媒体问询',
      rc: '人才求职',
      beian: '京ICP备2022022021号',
      dizhi: '公司地址：北京市海淀区黑泉路8号康健宝盛广场C座2008室'

    }
  }
}