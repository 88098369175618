export default {
  lang: {
    header: {
      //公司名称
      SpaceIoT:'SpaceIoT',
      // 头部导航栏
      technology: 'technology',
      product: 'product',
      market: 'market',
      news: 'news',
      consult: 'consult',
      we: 'we',
      join: 'join',
      serch: 'search'
    },
    advertisement: {
      // 广告
      about: 'About satellite iot',
      more: 'Learn more',
      // 框1
      reach: 'Everything is within reach',
      nee: 'As far as the eye can see',
      introduce:
        'Network links are realized in every corner of the world, and data services connecting the world are provided for individuals, enterprises and devices',
      product: 'Learn more about our technology',
      // 框2
      technology: 'Learn more about our products',
      market: 'Learn more about our market'
    },
    content: {
      // 技术1 框1
      omnipresent: 'omnipresent Satellite',
      things: 'Internet of Things',
      coverage:
        'Terrestrial communications networks cover only 10% of the world s surface area, while satellite communications networks can achieve 100% coverage',
      details: 'Learn technical details',
      // 框3
      lose: 'Never lose contact',
      net: 'Object-space system',
      cost: 'We are committed to providing low-cost network solutions for any region of the world, ensuring that you will never exceed your network coverage',
      us: 'Contact us',
      // 技术2
      network: 'network',
      any: 'Suitable for any bending network,Cooperation via satellite,Global coverage',
      mass: 'Mass access',
      low: 'Low-latency transmission',
      design: 'Low powerDesign',
      tiandi: 'Tiandi Story communication protocol(WUYU Protocol)',
      comon: 'Dual communication',
      guangbo: 'Broadcast service',
      peizhi: 'configurable',
      mozu: 'Modules & Base stations',
      shuzi:
        'Full time 100% data chain, digital, information, intelligent, safe, cost reduction, efficiency.',
      // 技术3
      hangye: 'Enable industry development',
      gaodu: 'New height',
      fazhan:
        'By cooperating with users to develop customized terminals and build cloud platforms, Penghu Wuyu provides users with customized one-stop industry solutions, and enables the development of the industry with satellite Internet of Things',
      renyuan: 'personnel',
      yunshu: 'transport',
      kaicai: 'mine',
      haishi: 'maritime affairs',
      nongye: 'agriculture',
      nengyuan: 'Source of energy',
      kangzai: 'Disaster',
      jiuyuan: 'rescue',
      shiyou: 'Oil & Gas',
      weilai: 'future'
    },
    prodect: {
      // 产品1
      wuyu: 'Monowoo THE X',
      weixing: 'Satellite iot communication module',
      wulian:
        'The iot X satellite iot communication module is based on the reconstruction of the communication chip of the ground narrowband system, through its own communication protocol, and the straight-link satellite. The product is mature and stable, with extremely low power consumption, easy integration, and can realize global narrowband bidirectional communication',
      liaojie: 'Learn products details',
      tianxian: 'antenna',
      tongxin: 'Communication module',
      chuanganqi: 'sensor',
      // 产品2
      shebei: 'Satellite personal communication equipment',
      fengwo:
        'When you are in a place where there is no cellular network, having reliable communication equipment is critical. Wuyu I emergency communication equipment provides you with convenient professional auxiliary functions and reliable protection. A 24/7/365 rescue service is provided in the event of danger through a robust satellite network',
      shebeiapp: 'Wuyu THE I',
      xingshi: 'Satellite Messenger',
      fengwoapp: 'Wuyu THE I satellite Messenger can provide customers with reliable real-time bidirectional satellite text communication in areas without ground, position tracking, efficient emergency rescue and real-time data return and other functions. Is a "small size, low cost, long standby" personal satellite short code communication equipment.',
      xiang: 'Learn products',
      //产品3
      haiyang: 'Marine buoy monitoring equipment',
      haiyangapp: 'Wuyu THE M',
      fubiao: 'Ocean Buoy',
      jiankong:
        'Wuyu M ocean buoy monitoring equipment, using existing commercial sensor devices, simple design, with low-power communication modules, to achieve "low cost, low power, long standby, environmental protection" ocean buoy products, equipped with temperature sensing, salinity sensing, inertial measurement unit/accelerometer, position sensing Beidou +GPS navigation module four types of general sensors. In addition, the ocean buoy will reserve expansion ports to facilitate access to other customized sensors such as sonar, cloud cameras, edge computing modules, terminal security modules, etc., to achieve comprehensive acquisition and monitoring of Marine information',
      peihe:
        'With THE corresponding AI algorithm, Wuyu THE M has developed functions such as ocean current prediction, vortex early warning, enemy information notification, and biological signs monitoring. To provide customers with Marine satellite Internet of Things data platform one-stop solution',
      //产品4
      gongye: 'Industrial iot monitoring equipment',
      wy: 'Iot D industrial Internet of Things monitoring equipment, configured with five types of industrial standard interface, no need to make a large number of adjustments to existing industrial equipment, through simple joint adjustment can achieve the existing equipment on the star, to achieve global real-time monitoring. Through the low-power satellite communication module, the new industrial satellite Internet of Things terminal with "low cost, low power consumption and long standby" is realized. With THE corresponding platform and algorithm, Wuyu THE D provides a one-stop solution, which can collect key data such as the operation of industrial equipment, equipment alarm, electronic fence, etc., to meet the core needs of industrial customers to reduce costs and increase efficiency',
      gongyeapp: 'Wuyu THE D',
      wxdtu: 'Satellite DTU',
      wyapp: 'Wuyu THE D satellite Internet of Things industrial terminal, configured with three types of communication interfaces (RS485, RS232, TTL), without a large number of adjustments to the existing sensing equipment, through simple coordination can achieve the existing equipment on the star, to achieve global real-time equipment monitoring and data return. Built-in self-developed 5GNTN low power satellite communication module, is a "low cost, low power, long standby" industrial satellite Internet of things new equipment.'
    },
    marketplace: {
      // 市场左侧 框1
      wangluo: 'Wuyu Network is here',
      quanqiu: 'global',
      changjing: 'Application scenario',
      zichan: 'Asset monitoring',
      yingji: 'Emergency rescue',
      huanjing: 'Environmental',
      hai: 'Ocean logistics',
      lianxi: 'Contact us',
      dianwang:
        'Power grid towers and oil pipelines are all over the world, most of which do not have ground network coverage, and satellite Internet of Things can effectively monitor the state of infrastructure, real-time alarm, and reduce losses',
      jy: 'The emergency rescue market mainly serves inspection personnel, crew, professional rescue personnel in the area without ground network coverage, as well as mountaineering, camping, skiing, hiking and other enthusiasts',
      hj: 'The environmental monitoring market mainly focuses on "water, air and soil" quality monitoring nationwide, and monitoring sensor equipment is generally deployed in sparsely populated areas without ground network coverage',
      bj: 'There are a large number of transport vessels and fishing vessels at sea, as well as a large number of containers, and Marine equipment can not build a ground network, only satellite communication can be used for equipment monitoring and alarm'
    },
    we: {

      // 我们
      yiqi: 'Together with Wu-woo',
      shijie: 'Linked world',
      beijing: 'Beijing Penghu Wuyu Technology Development Co., LTD., is the world s first commercial aerospace leader to complete the "one earth, one Earth" communication protocol, and realize low-cost satellite Internet of Things; It is a global low-cost satellite Internet of Things operator and solution provider, providing global customers with full-time, full and global Internet of Things equipment monitoring and data acquisition services',
      jianchi: 'Peng Hu Wuyu adhere to the "domestic proofing, global layout" of the global operation model. Domestically, based on the policy advantages that the Ministry of Industry and Information Technology has clearly included the heaven and earth into the focus of the 14th Five-Year Plan, the satellite Internet of Things "takes advantage of the trend" and can be expected in the future. Globally, Peng Hu Wuyu has docking and cooperation with many overseas enterprises and state-owned enterprises going out in Latin America, Europe and the Middle East, and plans to copy the Chinese model overseas as soon as possible, form a global operation layout, and build a global sky and sky data system',
      chengwei: 'At present, on the basis of the successful verification of the Heaven and Earth communication protocol, Penghu Wuyu has synchronously completed the development of satellite Internet of Things core product prototypes, including satellite Internet of Things chips and modules, industrial terminals and portable terminals, data platforms, etc., and has met the conditions for trial commercial operation. On June 18, 2022, together with leading international chip manufacturers and satellite manufacturers, Penghu completed the on-orbit coordination of ground communication protocols, ground chips and existing satellites, becoming',
      shixian: 'The world s first low-cost satellite Internet of Things service provider that realizes the closed-loop of "one earth and one Earth" technology and can provide commercial operation',
      shiming: 'Mission',
      tiandi: 'Heaven and earth are one, all things are connected',
      yuanjing: 'vision',
      quanqiu: 'To become the world s largest integrated communications technology and information service provider',
      jiazhi: 'value',
      chengshi: 'Look at the stars, down to earth; In the best, moral',
      // 关于我们
      chanpin: 'Product website',
      jr: 'join us',
      fw: 'Service agreement',
      ys: 'Privacy agreement',
      hz: 'Cooperative inquiry',
      mt: 'Media inquiry',
      rc: 'Talent search',
      beian: 'Beijing ICP for 2022022021',
      dizhi: 'Address: Room 2008, Block C, Kangjian Baosheng Plaza, No.8 Heiquan Road, Haidian District, Beijing'

    }
  }
}