<template>
  <div class="homes">
     <header style="width: 100%" class="header_top">
          <!-- nav部分 -->
          <div class="grid-content bg-purple-light">
            <span class="animate">{{ $t("lang.header.SpaceIoT") }}</span>
          </div>
          <div>
            <!-- 菜单 -->
            <div class="header_nav">
              <div
                class="row"
                style="height: 70px; width: 45%; float: left; margin-left: 23%"
              >
                <nav
                  class="navbar navbar-expand-lg navbar-light"
                  style="background: white; width: 100%"
                >
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <a class="nav-link" href="#technology">{{
                        $t("lang.header.technology")
                      }}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#product">{{
                        $t("lang.header.product")
                      }}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#market">{{
                        $t("lang.header.market")
                      }}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link targetClick" href="#"
                        ><img :src="`${imgUrl}logo.png`"
                      /></a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="#consult"
                        >{{ $t("lang.header.consult") }}</a
                      >
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#us">{{
                        $t("lang.header.we")
                      }}</a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        style="
                          pointer-events: none;
                          cursor: default;
                          color: gray;
                        "
                        href="#join"
                        >{{ $t("lang.header.join") }}</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>

              <!-- 搜索框和语言切换 -->
              <div style="float: right; width: 20%; height: 70px">
                <input
                  style="
                    border: bold 1px #b2b2b2;
                    width: 121px;
                    height: 24px;
                    margin-top: 23px;
                    margin-right: 40px;
                    font-size: 13px;
                    float: left;
                    text-align: center;
                  "
                  :placeholder="$t('lang.header.serch')"
                />
                <div
                  class="nav-link"
                  style="color: #b2b2b2; font-size: 14px; margin-top: 27px"
                >
                  <span @click="hh" style="cursor: pointer">{{ text }}</span>
                </div>
              </div>
            </div>
          </div>
        </header>
  <div class="home">
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">

        <!--广告-->
        <div
          style="position: fixed; z-index: 1000; top: 150px; width: 100%"
          id="advice"
          v-if="open"
        >
          <div
            style="
              background-color: #492cef;
              height: 64px;
              width: 230px;
              float: right;
              margin-right: 3%;
            "
          >
            <h3
              style="
                font-size: 26px;
                color: white;
                float: right;
                margin-right: 10px;
                margin-top: 0px;
                cursor: pointer;
              "
              @click="close"
            >
              ×
            </h3>
            <h4
              style="
                color: white;
                float: left;
                width: 80%;
                margin-top: 10px;
                margin-left: 10px;
                font-weight: bold;
                font-size: 18px;
              "
            >
              {{ $t("lang.advertisement.about") }}
            </h4>
            <a
              style="
                color: white;
                float: left;
                margin-left: 10px;
                font-size: 18px;
              "
              href="#technology"
              >{{ $t("lang.advertisement.more") }}
              <i class="el-icon-arrow-right" style="font-size: 13px"></i>
            </a>
          </div>
        </div>
        <div style="100%;overflow: hidden;">
          <!--轮播图-->
          <div class="app-container new" style="margin-top: 105px; width: 100%" >
            <el-carousel
            :show-arrow="false"
              :height="hdgd"
              style="overflow: hidden"
            >
              <el-carousel-item
                v-for="(item, index) in imgList"
                :key="item.id"
                class="el-carousel"
              >
                <div class="app_body">
                  <!--框1-->
                  <div class="app_content" v-if="index == 0">
                    <div
                      style="
                        width: 100%;
                        height: 60px;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                      "
                    >
                      <div class="app_title">
                        {{ $t("lang.advertisement.reach") }},
                      </div>
                      <div class="app_title">
                        {{ $t("lang.advertisement.nee") }}。
                      </div>
                    </div>
                    <div class="app_text">
                      {{ $t("lang.advertisement.introduce") }}。
                    </div>
                    <div class="app_body">
                      <div class="body_left">
                        {{ $t("lang.advertisement.product") }}
                      </div>
                      <div class="body_rigth">
                        <i class="el-icon-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                  <!--框2-->
                  <div class="app_content" v-if="index == 1">
                    <div
                      style="
                        width: 100%;
                        height: 60px;

                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                      "
                    >
                      <div class="app_title">
                        {{ $t("lang.advertisement.reach") }},
                      </div>
                      <div class="app_title">
                        {{ $t("lang.advertisement.nee") }}。
                      </div>
                    </div>
                    <div class="app_text">
                      {{ $t("lang.advertisement.introduce") }}。
                    </div>
                    <div class="app_body">
                      <div class="body_left">
                        {{ $t("lang.advertisement.technology") }}
                      </div>
                      <div class="body_rigth">></div>
                    </div>
                  </div>
                  <!--框3-->
                  <div class="app_content" v-if="index == 2">
                    <div
                      style="
                        width: 100%;
                        height: 60px;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                      "
                    >
                      <div class="app_title">
                        {{ $t("lang.advertisement.reach") }},
                      </div>
                      <div class="app_title">
                        {{ $t("lang.advertisement.nee") }}。
                      </div>
                    </div>
                    <div class="app_text">
                      {{ $t("lang.advertisement.introduce") }}。
                    </div>
                    <div class="app_body">
                      <div class="body_left">
                        {{ $t("lang.advertisement.market") }}
                      </div>
                      <div class="body_rigth">
                        <i class="el-icon-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <img style='width:100%;height: inherit;' :src="`${imgUrl}` + item.img" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div
          class="container"
          style="width: 100%; background-color: #3a3a3a; height: 100%"
        >
          <!--技术-->
          <div style="width: 70%; margin: auto" class="js" id="technology">
            <!--技术1-->
            <div style="width: 100%; margin-top: 160px">
              <div
                style="
                  height: 100px;
                  background-color: #3a3a3a;
                  margin-top: 30px;
                "
              >
                <!-- 左侧 -->
                <div
                  class="left_images"
                  style="
                    float: left;
                    width: 28%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                  "
                >
                  <!-- 框1 -->
                  <div
                    style="
                      display: flex;
                      justify-content: space-around;
                      align-items: center;
                      flex-direction: column;
                      margin-top: 100px;
                    "
                  >
                    <div
                      style="
                        width: 80%;
                        font-size: 25px;
                        font-weight: bold;
                        color: white;
                      "
                    >
                      {{ $t("lang.content.omnipresent") }}<br />{{
                        $t("lang.content.things")
                      }}
                    </div>
                    <div
                      style="
                        margin-top: 40px;
                        width: 80%;
                        text-align: left;
                        color: white;
                        font-size: 14px;
                      "
                    >
                      {{ $t("lang.content.coverage") }}
                    </div>
                    <div style="width: 100%; cursor: pointer; height: 10px">
                      <div
                        style="
                          width: 80%;
                          font-size: 12px;
                          background-color: white;
                          color: #492cef;
                          cursor: pointer;
                          height: 32px;
                          line-height: 32px;
                          margin: auto;
                          margin-top: 20px;
                        "
                      >
                        <p style="margin-left: 5px; float: left; color: black">
                          {{ $t("lang.content.details") }}
                        </p>
                        <span style="float: right; margin-right: 20px"
                          ><i class="el-icon-arrow-right"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%; height: 408px"></div>
                  <!-- 框3 -->
                  <div
                    style="
                      display: flex;
                      justify-content: space-around;
                      align-items: center;
                      flex-direction: column;
                      margin-bottom: 100px;
                    "
                  >
                    <div
                      style="
                        width: 80%;
                        font-size: 25px;
                        font-weight: bold;
                        color: #492cef;
                      "
                    >
                      {{ $t("lang.content.lose") }}<br />{{
                        $t("lang.content.net")
                      }}
                    </div>
                    <br />
                    <div
                      style="
                        margin-top: 15px;
                        width: 80%;
                        text-align: left;
                        color: white;
                        font-size: 14px;
                      "
                    >
                      {{ $t("lang.content.cost") }}。
                    </div>
                    <br />
                    <div
                      style="
                        width: 80%;
                        font-size: 12px;
                        background-color: #492cef;
                        color: white;
                        cursor: pointer;
                        height: 32px;
                        line-height: 32px;
                      "
                    >
                      <p style="margin-left: 5px; float: left">
                        {{ $t("lang.content.us") }}
                      </p>
                      <span style="float: right; margin-right: 20px"
                        ><i class="el-icon-arrow-right"></i
                      ></span>
                    </div>
                  </div>
                </div>
                <!-- 右侧 -->
                <div
                  style="
                    float: right;
                    width: 40%;
                    background-color: #3a3a3a;
                    margin-top: 80px;
                  "
                >
                  <img
                    :src="`${imgUrl}jishu2-1.png`"
                    style="width: 90%; margin-top: 110px"
                  />
                  <div style="width: 100%; height: 250px"></div>
                  <img :src="`${imgUrl}jishu2-2.png`" style="width: 90%" />
                </div>
              </div>
            </div>
            <!-- 技术2 -->
            <div
              class="jianbian"
              style="
                width: 100%;
                height: 650px;
                color: white;
                text-align: center;
              "
            >
              <h1 style="padding-top: 80px">
                {{ $t("lang.content.network") }}
              </h1>
              <p style="font-size: 16px; padding-top: 10px">
                {{ $t("lang.content.any") }}
              </p>
              <div
                style="
                  padding-top: 40px;
                  width: 50%;
                  height: 60px;
                  margin: auto;
                  display: flex;
                  justify-content: space-around;
                  font-size: 13px;
                  overflow: hidden;
                "
              >
                <span>{{ $t("lang.content.mass") }}</span>
                <span>{{ $t("lang.content.low") }}</span>
                <span>{{ $t("lang.content.design") }}</span>
              </div>
              <h2
                style="margin-top: 60px; margin-bottom: 70px; font-size: 30px"
              >
                {{ $t("lang.content.tiandi") }}
              </h2>
              <div
                style="
                  width: 50%;
                  height: 60px;
                  margin: auto;
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  font-size: 13px;
                "
              >
                <span>{{ $t("lang.content.comon") }}</span>
                <span>{{ $t("lang.content.guangbo") }}</span>
                <span>{{ $t("lang.content.peizhi") }}</span>
              </div>
              <h3 style="font-size: 30px; padding-top: 25px">
                {{ $t("lang.content.mozu") }}
              </h3>
              <p style="font-size: 16px; padding-top: 10px">
                {{ $t("lang.content.shuzi") }}
              </p>
            </div>
            <!--技术3-->
            <div
              style="
                width: 100%;
                height: 1000px;
                margin-top: 150px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
              "
            >
              <div
                style="
                  width: 100%;
                  height: 80px;
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  flex-direction: column;
                "
              >
                <h1 style="color: white; font-weight: 900">
                  {{ $t("lang.content.hangye")
                  }}<span style="color: #492cef">{{
                    $t("lang.content.gaodu")
                  }}</span>
                </h1>
                <br />
                <p style="color: white; font-size: 16px">
                  {{ $t("lang.content.fazhan") }}
                </p>
              </div>
              <!--中间图片-->
              <div
                style="
                  width: 100%;
                  height: 870px;
                  display: flex;
                  justify-content: space-between;
                  flex-direction: column;
                  overflow: hidden;
                "
              >
                <tr
                  style="
                    width: 100%;
                    height: 410px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                  "
                >
                  <td>
                    <span
                      style="
                        position: absolute;
                        margin-top: 370px;
                        font-size: 15px;
                        margin-left: 10px;
                        color: white;
                      "
                      >{{ $t("lang.content.renyuan") }}</span
                    ><img
                      :src="`${imgUrl}jishu1.png`"
                      style="width: 100%; height: 410px"
                    />
                  </td>
                  <td>
                    <span
                      style="
                        position: absolute;
                        margin-top: 370px;
                        font-size: 15px;
                        margin-left: 10px;
                        color: white;
                      "
                      >{{ $t("lang.content.yunshu") }}</span
                    ><img
                      :src="`${imgUrl}jishu2.png`"
                      style="width: 100%; height: 410px"
                    />
                  </td>
                  <td>
                    <span
                      style="
                        position: absolute;
                        margin-top: 370px;
                        font-size: 15px;
                        margin-left: 10px;
                        color: white;
                      "
                      >{{ $t("lang.content.kaicai") }}</span
                    ><img
                      :src="`${imgUrl}jishu3.jpg`"
                      style="width: 100%; height: 410px"
                    />
                  </td>
                  <td>
                    <span
                      style="
                        position: absolute;
                        margin-top: 370px;
                        font-size: 15px;
                        margin-left: 10px;
                        color: white;
                      "
                      >{{ $t("lang.content.haishi") }}</span
                    ><img
                      :src="`${imgUrl}jishu4.png`"
                      style="width: 100%; height: 410px"
                    />
                  </td>
                  <td>
                    <span
                      style="
                        position: absolute;
                        margin-top: 370px;
                        font-size: 15px;
                        margin-left: 10px;
                        color: white;
                      "
                      >{{ $t("lang.content.nongye") }}</span
                    ><img
                      :src="`${imgUrl}jishu5.jpg`"
                      style="width: 100%; height: 410px"
                    />
                  </td>
                </tr>
                <tr
                  style="
                    width: 100%;
                    height: 410px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                  "
                >
                  <td>
                    <span
                      style="
                        position: absolute;
                        margin-top: 370px;
                        font-size: 15px;
                        margin-left: 10px;
                        color: white;
                      "
                      >{{ $t("lang.content.shiyou") }}</span
                    ><img
                      :src="`${imgUrl}jishu6.png`"
                      style="width: 100%; height: 410px"
                    />
                  </td>
                  <td>
                    <span
                      style="
                        position: absolute;
                        margin-top: 370px;
                        font-size: 15px;
                        margin-left: 10px;
                        color: white;
                      "
                      >{{ $t("lang.content.kangzai") }}</span
                    ><img
                      :src="`${imgUrl}jishu7.png`"
                      style="width: 100%; height: 410px"
                    />
                  </td>
                  <td>
                    <span
                      style="
                        position: absolute;
                        margin-top: 370px;
                        font-size: 15px;
                        margin-left: 10px;
                        color: white;
                      "
                      >{{ $t("lang.content.jiuyuan") }}</span
                    ><img
                      :src="`${imgUrl}jishu8.png`"
                      style="width: 100%; height: 410px"
                    />
                  </td>
                  <td>
                    <span
                      style="
                        position: absolute;
                        margin-top: 370px;
                        font-size: 15px;
                        margin-left: 10px;
                        color: white;
                      "
                      >{{ $t("lang.content.nengyuan") }}</span
                    ><img
                      :src="`${imgUrl}jishu9.png`"
                      style="width: 100%; height: 410px"
                    />
                  </td>
                  <td>
                    <span
                      style="
                        position: absolute;
                        margin-top: 370px;
                        font-size: 15px;
                        margin-left: 10px;
                        color: white;
                      "
                      >{{ $t("lang.content.weilai") }}</span
                    ><img
                      :src="`${imgUrl}jishu10.png`"
                      style="width: 100%; height: 410px"
                    />
                  </td>
                </tr>
              </div>
            </div>
            <div style="100%;height:200px;" id="product"></div>
            <!--产品1-->
            <div
              style="
                width: 100%;
                overflow: hidden;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
              "
            >
              <div
                style="
                  width: 90%;
                  height: 220px;
                  margin: auto;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <!--左侧-->
                <div
                  style="
                    width: 350px;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                  "
                >
                  <!-- 框1 -->
                  <p style="font-size: 30px; font-weight: bold; color: #afadad">
                    {{ $t("lang.prodect.wuyu") }}
                  </p>
                  <p style="font-size: 18px; font-weight: bold; color: white">
                    {{ $t("lang.prodect.weixing") }}
                  </p>
                  <div style="color: white; font-size: 13px">
                    {{ $t("lang.prodect.wulian") }}。
                  </div>
                  <div style="width: 100%; cursor: pointer">
                    <div
                      style="
                        width: 100%;
                        font-size: 12px;
                        color: white;
                        cursor: pointer;
                        height: 32px;
                        line-height: 32px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        background: blue;
                      "
                    >
                      <p>{{ $t("lang.prodect.liaojie") }}</p>
                      <span><i class="el-icon-arrow-right"></i></span>
                    </div>
                  </div>
                </div>
                <!-- 中间图片 -->
                <img :src="`${imgUrl}chanpin1-1.png`" style="width: 200px" />
                <!-- 右侧 -->
                <div
                  style="
                    width: 230px;
                    height: 210px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <!--左侧图片-->
                  <div
                    style="
                      width: 43px;
                      height: 210px;
                      display: flex;
                      justify-content: space-around;
                      flex-direction: column;
                    "
                  >
                    <img
                      :src="`${imgUrl}chanpin1-2.png`"
                      style="width: 40px; height: 40px"
                    />
                    <img :src="`${imgUrl}chanpin1-3.png`" style="width: 40px" />
                    <img :src="`${imgUrl}chanpin1-4.png`" style="width: 40px" />
                  </div>
                  <!--右侧文字-->
                  <div
                    style="
                      width: 155px;
                      height: 170px;
                      display: flex;
                      justify-content: space-between;
                      align-items: flex-start;
                      flex-direction: column;
                      font-size: 13px;
                    "
                  >
                    <span style="color: white">{{
                      $t("lang.prodect.tianxian")
                    }}</span>
                    <span style="color: white; margin-top: 16px">{{
                      $t("lang.prodect.tongxin")
                    }}</span>
                    <span style="color: white">{{
                      $t("lang.prodect.chuanganqi")
                    }}</span>
                  </div>
                  <!-- <div
                    style="
                      width: 105px;
                      height: 50px;
                      display: flex;
                      background: #4fe2f1;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                  </div> -->
                </div>
              </div>
              <!-- 底部图片 -->
              <img
                :src="`${imgUrl}chanpin1-5.jpg`"
                style="width: 100%; height: 300px; margin-top: 70px"
              />
            </div>
            <!--产品2-->
            <div
              style="
                width: 100%;
                height: 500px;
                color: white;
                margin-top: 230px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <!-- 左侧文字 -->
              <div
                style="
                  padding-left: 60px;
                  width: 310px;
                  height: 360px;
                  display: flex;
                  justify-content: space-around;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <p style="font-size: 30px; font-weight: bold; color: #ccff7a">
                  {{ $t("lang.prodect.wuyu") }}
                </p>
                <p style="font-size: 18px; font-weight: bold; color: white">
                  {{ $t("lang.prodect.shebei") }}
                </p>
                <p style="color: white; font-size: 15px">
                  {{ $t("lang.prodect.fengwo") }}.
                </p>
                <div style="width: 100%; cursor: pointer">
                  <div
                    style="
                      width: 100%;
                      font-size: 12px;
                      background-color: #ccff7a;
                      color: black;
                      cursor: pointer;
                      margin: auto;
                      height: 32px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <p style="float: left">{{ $t("lang.prodect.xiang") }}</p>
                    <span><i class="el-icon-arrow-right"></i></span>
                  </div>
                </div>
              </div>
              <!-- 右侧图片 -->
              <img
                :src="`${imgUrl}chanpin2.png`"
                style="float: right; width: 48%"
              />
            </div>
            <!--  产品3 -->
            <div
              style="
                width: 100%;
                height: 500px;
                color: white;
                margin-top: 230px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <!-- 左侧图片 -->
              <img
                :src="`${imgUrl}chanpin3.png`"
                style="width: 53%; margin-right: 10px"
              />
              <!-- 右侧文字 -->
              <div
                style="
                  width: 430px;
                  height: 460px;
                  display: flex;
                  justify-content: space-around;
                  align-items: flex-start;
                  flex-direction: column;
                "
              >
                <p style="font-size: 30px; font-weight: bold; color: #492cef">
                  {{ $t("lang.prodect.wuyu") }}
                </p>
                <p style="font-size: 18px; font-weight: bold; color: white">
                  {{ $t("lang.prodect.haiyang") }}
                </p>
                <p style="color: white; font-size: 14px">
                  {{ $t("lang.prodect.jiankong") }}。
                  <br />
                  {{ $t("lang.prodect.peihe") }}。
                </p>
                <div style="width: 100%; cursor: pointer">
                  <div
                    style="
                      width: 100%;
                      font-size: 12px;
                      color: white;
                      cursor: pointer;
                      margin: auto;
                      height: 32px;
                      line-height: 32px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      background: blue;
                    "
                  >
                    <p>{{ $t("lang.prodect.xiang") }}</p>
                    <span><i class="el-icon-arrow-right"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <!--  产品4 -->
            <div
              style="
                width: 100%;
                height: 500px;
                color: white;
                margin-top: 230px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <!-- 左侧文字 -->
              <div
                style="
                  width: 420px;
                  height: 410px;
                  display: flex;
                  justify-content: space-around;
                  align-items: flex-start;
                  flex-direction: column;
                  margin-right: 10px;
                "
              >
                <span
                  style="font-size: 30px; font-weight: bold; color: #fbaa16"
                  >{{ $t("lang.prodect.wuyu") }}</span
                >
                <span
                  style="font-size: 18px; font-weight: bold; color: white"
                  >{{ $t("lang.prodect.gongye") }}</span
                >
                <span style="color: white; font-size: 15px"
                  >{{ $t("lang.prodect.wy") }}。</span
                >
                <div style="width: 100%; cursor: pointer">
                  <div
                    style="
                      width: 100%;
                      font-size: 12px;
                      background-color: #fbaa16;
                      color: black;
                      cursor: pointer;
                      height: 32px;
                      line-height: 32px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <p>{{ $t("lang.prodect.xiang") }}</p>
                    <span><i class="el-icon-arrow-right"></i></span>
                  </div>
                </div>
              </div>
              <!-- 右侧图片 -->
              <img :src="`${imgUrl}chanpin4.png`" style="width: 50%" />
            </div>
            <div style="width: 100%; height: 240px" id="market"></div>
            <!--市场-->
            <div
              style="
                width: 100%;
                height: 470px;
                display: flex;
                margin-bottom:100px;
                justify-content: space-between;
              "
            >
              <!--左侧-->
              <div
                style="
                  width: 500px;
                  display: flex;
                  justify-content: space-between;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <h1 style="color:white;font-size=28px;">
                  {{ $t("lang.marketplace.wangluo")
                  }}<span style="color: aqua">
                    {{ $t("lang.marketplace.quanqiu") }}</span
                  >
                  {{ $t("lang.marketplace.changjing") }}
                </h1>
                <!--中间btn-->
                <ul class="error">
                  <li
                    :class="{ active: itemIndex == 1 }"
                    @click="btnClick(1)"
                    style="
                      cursor: pointer;
                      width: 100%;
                      height: 51px;
                      display: flex;
                      justify-content: space-around;
                      align-items: center;
                    "
                  >
                    {{ $t("lang.marketplace.zichan") }}
                  </li>
                  <li
                    :class="{ active: itemIndex == 2 }"
                    @click="btnClick(2)"
                    style="
                      cursor: pointer;
                      width: 100%;
                      height: 51px;
                      display: flex;
                      justify-content: space-around;
                      align-items: center;
                    "
                  >
                    {{ $t("lang.marketplace.yingji") }}
                  </li>
                  <li
                    :class="{ active: itemIndex == 3 }"
                    @click="btnClick(3)"
                    style="
                      cursor: pointer;
                      width: 100%;
                      height: 51px;
                      display: flex;
                      justify-content: space-around;
                      align-items: center;
                    "
                  >
                    {{ $t("lang.marketplace.huanjing") }}
                  </li>
                  <li
                    :class="{ active: itemIndex == 4 }"
                    @click="btnClick(4)"
                    style="
                      cursor: pointer;
                      width: 100%;
                      height: 51px;
                      display: flex;
                      justify-content: space-around;
                      align-items: center;
                    "
                  >
                    {{ $t("lang.marketplace.hai") }}
                  </li>
                </ul>
                <!--底部-->
                <span style="width: 100%; cursor: pointer; margin-top: 50px">
                  <div
                    style="
                      width: 60%;
                      font-size: 12px;
                      background: #4fe2f1;
                      color: black;
                      cursor: pointer;
                      height: 32px;
                      line-height: 32px;
                    "
                  >
                    <p style="margin-left: 5px; float: left">
                      {{ $t("lang.marketplace.lianxi") }}
                    </p>
                    <span style="float: right; margin-right: 20px"
                      ><i class="el-icon-arrow-right"></i
                    ></span>
                  </div>
                </span>
              </div>
              <!--右侧-->
              <div
                style="
                  width: 60%;
                  height: 103%;
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                "
              >
                <div
                  style="
                    width: 37%;
                    height: 360px;
                    color: white;
                    font-size: 13px;
                  "
                >
                  <div
                    v-if="itemIndex == 1"
                    class="detail_item"
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      flex-direction: column;
                    "
                  >
                    <img :src="`${imgUrl}shichang1.png`" style="width: 100%" />
                    <p style="color: white; margin-top: 7px">
                      {{ $t("lang.marketplace.dianwang") }}。
                    </p>
                  </div>
                  <div
                    v-if="itemIndex == 2"
                    class="detail_item"
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      flex-direction: column;
                    "
                  >
                    <img :src="`${imgUrl}shichang2.png`" style="width: 100%" />
                    <p style="color: white; margin-top: 7px">
                      {{ $t("lang.marketplace.jy") }}。
                    </p>
                  </div>
                  <div
                    v-if="itemIndex == 3"
                    class="detail_item"
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      flex-direction: column;
                    "
                  >
                    <img
                      :src="`${imgUrl}shichang3.png`"
                      alt=""
                      style="width: 100%; height: 300px"
                    />
                    <div
                      class="itme_text"
                      style="font-size: 13px; margin-top: 7px"
                    >
                      {{ $t("lang.marketplace.hj") }}。
                    </div>
                  </div>
                  <div
                    v-if="itemIndex == 4"
                    class="detail_item"
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      flex-direction: column;
                    "
                  >
                    <img :src="`${imgUrl}shichang4.png`" style="width: 100%" />
                    <p style="color: white; margin-top: 7px">
                      {{ $t("lang.marketplace.bj") }}。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!--收费咨询-->
            <div id="consult" class="consult-wraper">
              <div v-for="(item,index) in consultList" :key="index" class="consult-item" @click="isCode = true">
                <div class="c-info">
                  <div class="c-title">{{item.title}}</div>
                  <div class="c-pay">{{item.pay}}</div>
                  <div class="c-word">{{item.word}}</div>
                </div>
                <div class="c-img">
                  <img :src="item.imgUrl" alt="">
                </div>
              </div>
            </div>
            <div style="width: 100%; height: 270px" id="us"></div>
            <!-- 我们 -->
            <div id="us">
              <h1 style="text-align: center; color: white; font-weight: bold">
                {{ $t("lang.we.yiqi")
                }}<span style="color: #ac53fd">{{ $t("lang.we.shijie") }}</span>
              </h1>
              <p style="color: white; margin-top: 50px; font-size: 16px">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                  $t("lang.we.beijing")
                }}。
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                  $t("lang.we.jianchi")
                }}。
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {{ $t("lang.we.chengwei") }}
                <span style="color: #ac53fd; font-weight: bold; font-size: 18px"
                  >{{ $t("lang.we.shixian") }}。</span
                >
              </p>
              <img
                :src="`${imgUrl}women1.jpg`"
                style="width: 100%; margin-top: 30px"
              />

              <div
                style="
                  text-align: center;
                  color: white;
                  margin-top: 80px;
                  font-size: 16px;
                "
              >
                <p>
                  <span style="font-weight: bold; color: ">{{
                    $t("lang.we.shiming")
                  }}</span>
                  <br />
                  <span>{{ $t("lang.we.tiandi") }}</span>
                </p>
                <br />
                <p>
                  <span style="font-weight: bold; color: ">{{
                    $t("lang.we.yuanjing")
                  }}</span>
                  <br />
                  <span>{{ $t("lang.we.quanqiu") }}</span>
                </p>
                <br />
                <p style="margin-bottom: 210px">
                  <span style="font-weight: bold; color: ">{{
                    $t("lang.we.jiazhi")
                  }}</span>
                  <br />
                  <span>{{ $t("lang.we.chengshi") }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--关于我们-->
        <footer
          style="
            width: 100%;
            height: 311px;
            background: #282828;
            display: flex;
            justify-content: space-around;
            align-items: center;
          "
        >
          <div
            style="
              width: 70%;
              height: 164px;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
            "
          >
            <div :class="{ cic: setup, ces: !setup }">
              <a href="#" style="color: white"
                ><span>{{ $t("lang.we.chanpin") }}</span></a
              >
              <span>|</span>
              <span>{{ $t("lang.we.jr") }}</span>
              <span>|</span>
              <span>{{ $t("lang.we.fw") }}</span>
              <span>|</span>
              <span>{{ $t("lang.we.ys") }}</span>
            </div>
            <div>
              <div :class="{ network: setup, net: !setup }">
                <li>{{ $t("lang.we.hz") }}：sale@spaceiot.com.cn</li>
                <li>{{ $t("lang.we.mt") }}：pr@spaceiot.com.cn</li>
                <li>{{ $t("lang.we.rc") }}：hr@spaceiot.com.cn</li>
              </div>
              <div style="color: white; font-size: 12px; margin-top: 5px">
                {{ $t("lang.we.dizhi") }}
              </div>
            </div>
            <a href="http://www.beian.gov.cn/">
              <div style="color: white">
                <img
                  :src="`${imgUrl}gongan.png`"
                  alt=""
                  style="font-size: 13px; margin-right: 5px"
                />{{ $t("lang.we.beian") }}
              </div>
            </a>
          </div>
        </footer>
      </el-col>
    </el-row>
  </div>
  <div class="code-dialog" v-if="isCode" @mousemove.prevent.stop>
    <div class="code-content">
      <div class="code-close" @click="isCode = false">✖️</div>
      <div class="code-box">支付宝扫一扫</div>
      <div class="code-box">付款2.5元即可查看剩余内容</div>
      <div class="code-img">
        <img src="../assets/consult/code.jpg" alt="">
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  data() {
    return {
      itemIndex: 1,
      setup: false,
      open: true,
      text:'中/EN',
      lang: "",
      imgUrl: "http://oss.xinyuekongjian.com/static/website/pc/",
      hdgd: "900px",
      imgList: [
        {
          id: 1,
          img: "banner1.jpg",
        },
        {
          id: 2,
          img: "banner2.jpg",
        },
        {
          id: 3,
          img: "banner3.png",
        },
      ],
      isCode:false,
      consultList:[
        {
          title:'vue的知识总结',
          pay:'付费2.5元阅读全文',
          imgUrl:require('../assets/consult/img1.jpg'),
          word:'vue知识点总结 1.vue生命周期 什么是vue的生命周期？ Vue实例从创建到销毁的过程，就是生命周期...'
        },
        {
          title:'java实现数据结构——队列的入队和出队',
          pay:'付费2.5元阅读全文',
          imgUrl:require('../assets/consult/img2.jpg'),
          word:'用java实现队列的入队出队 首先要定义几个变量与数组： a：表示队列的数组 rear：表示队列尾...'
        },
        {
          title:'Python经典入门--变量及打印--3',
          pay:'付费2.5元阅读全文',
          imgUrl:require('../assets/consult/img3.jpg'),
          word:'1 数据类型 1.1 整型(int),浮点型(float),布尔型(bool),字符串型(str)...'
        },
        {
          title:'计算机项目部第二次部门培训——Python语法基础',
          pay:'付费2.5元阅读全文',
          imgUrl:require('../assets/consult/img4.jpg'),
          word:'1、基础知识 1.1 变量和赋值语句 #使用Python变量前必须给它赋值，因为其无默认值 x=3...'
        },
        {
          title:'css元素居中（水平居中、垂直居中）',
          pay:'付费2.5元阅读全文',
          imgUrl:require('../assets/consult/img5.png'),
          word:'实用的居中居中组合 2 种实现方案（包含不固定高度时的居中）水平居中 3 种实现方案垂直居中...'
        },
        {
          title:'Hystrix 集群 及 集群监控 Turbine',
          pay:'付费2.5元阅读全文',
          imgUrl:require('../assets/consult/img6.jpg'),
          word:'Hystrix 集群及监控 turbine 前面Dashboard演示的仅仅是单机服务监控，实际项目基本都是集群，所以这里集群监控用的是turbine...'
        },
        {
          title:'css权重及计算',
          pay:'付费2.5元阅读全文',
          imgUrl:require('../assets/consult/img7.jpg'),
          word:'值从左到右，左面的最大，一级大于一级，数位之间没有进制，级别之间不可超越。 关于CSS权重，我们需要一套计算公式来去计算，这个就是 CSS Specificity（特殊性） ...'
        },
      ]
    };
  },
  methods: {
    btnClick(index) {
      console.log(index);

      this.itemIndex = index;
    },
    hh() {
      // this.$i18n.locale = this.setup ? "zh" : "en";
      this.$i18n.locale = this.$i18n.locale === "zh" ? "en" : "zh";
      localStorage.setItem("language", this.$i18n.locale);
      this.setup = !this.setup;
      // this.placeholder =!this.placeholder;

    },
    close() {
      this.open = !this.open;
    },

  },
  mounted() {
    let that = this;
    window.onresize = function windowResize() {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      var w = window.innerWidth;
      var h = 900;
      if (w > 1679) {
        h = 900;
      } else {
        h = 0.536 * w;
      }
      that.hdgd = h + "px";
      console.log(that.hdgd);
    };
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      this.$i18n.locale = storedLanguage;
    }
  }
};
</script>
<style scoped lang="scss">
.consult-wraper {
  padding-top:100px;
  margin-top:-100px;
  .consult-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #dcdcdc;
    padding: 50px 0;
    .c-info {
      color: #fff;
      margin-right: 20px;
      .c-title {
        font-size: 20px;
        font-weight: 500;
      }
      .c-pay {
        margin-bottom: 25px;
        font-size: 14px;
      }
      .c-word {
        font-size: 14px;
      }
    }
    .c-img {
      width: 200px;
      height: 150px;
      display: flex;
      align-items: center;
      border: 1px solid #ccc;
      img {
        width: 200px;
        height: 120px;
        margin:  auto;
      }
    }
  }
}
.code-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($color: #000000, $alpha: 0.5);
  .code-content {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    position: relative;
    border-radius: 4px;
    .code-box {
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    .code-close {
      position: absolute;
      top: 10px;
      right: 15px;
      font-size: 20px;
      cursor: pointer;
    }
    .code-img {
      margin-top: 20px;
      width: 200px;
      height: 200px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.home{
 max-width: 1970px;
 height:100%;
 margin:auto;
}
.success {
  width: 64px;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: #afadad;
  margin-top: 29px;
  margin-left: 20px;
  font-size: 16px;
}



.error {
  width: 139px;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  color: #afadad;
  margin-top: 29px;
  margin-left: 20px;
  font-size: 16px;
}

.network {
  width: 600px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 12px;
}

.net {
  width: 750px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 12px;
}

.cic {
  width: 300px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 13px;
}

.ces {
  width: 520px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 13px;
}

// .app_body:nth-of-type(1) {
//   img {
//     width: 100%;
//     height: inherit;
//   }
// }

.home {
  position: relative;
}
.header_top {
  position: fixed;
  top: 0px;
  z-index: 999;
}
.header_nav {
  width: 100%;
  height: 70px;
  background: white;
}
.bg-purple-light {
  background: #3a3a3a;
  color: white;
  height: 36px;
  overflow: hidden;
  line-height: 36px;

  .animate {
    padding-left: 100%;
    display: inline-block;
    white-space: nowrap;
    animation: 30s wordsLoop linear infinite normal;
  }
}

@keyframes wordsLoop {
  0% {
    transform: translateX(200px);
  }

  100% {
    transform: translateX(-100%);
  }
}

ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 15px;
}

.row {
  width: 100%;
  height: 70px;
  overflow: hidden;
}

.imags {
  /* width: 100%; */
  background: url("../assets/images/banner1.jpg");
  background-repeat: no-repeat;
  /* background-size:100% 100%; */
  /* background-attachment:fixed; */
  overflow: hidden;
}

.left {
  margin-top: 80px;
  /* position: relative; */
  background-color: white;
  width: 350px;
  height: 230px;
  text-align: center;
  overflow: hidden;
}

.text {
  margin-right: 170px;
  font-weight: bolder;
  font-size: 21px;
  margin-top: 10px;
}
.text-center {
  margin-top: 40px;
  margin-right: 16px;
  font-size: 15px;
}

.flex_box {
  width: 85%;
  height: 300px;
  /* background: pink; */
  margin: auto;
}

.left_images {
  background: url("../assets/images/jishu-backage.png");
  background-size: 450px;
  height: 1200px;
}
.jianbian {
  background-image: url("../assets/images/background-jishu2.png");
  clear: both;
  margin: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 160px;
  /* background-size:100% 100%;
  background-attachment:fixed; */
}
.js {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.home ::v-deep.wrap.is-left {
  margin-right: -200px;
}

.active {
  color: aqua;
  border-bottom: 1px solid aqua;
}

.app-container {
  .app_content {
    width: 350px;
    height: 220px;
    background-color: #fff;
    position: absolute;
    left: 100px;
    top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    .app_title {
      margin-left: 20px;
      font-weight: 900;
      font-size: 20px;
    }
    .app_text {
      width: 95%;
      height: 50px;
      // background: red;
      // margin-top: 20px;
      margin-left: 20px;
      font-size: 15px;
      // text-indent: 20px;
    }
    .app_body {
      width: 90%;
      height: 30px;
      background-color: #492cef;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      color: #fff;
      text-align: center;
      font-size: 14px;
      line-height: 30px;
    }
  }
}

::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  opacity: 0.5;
}
::v-deep .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
}

</style>
